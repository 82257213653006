import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  ButtonBase,
  ClickAwayListener,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material';

// third-party
import { fetchUserAttributes, signOut } from 'aws-amplify/auth';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import { activeItem } from 'store/reducers/menu';

// assets
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';

const Profile = () => {
  const theme = useTheme();
  const { defaultId } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');

  const handleLogout = async () => {
    await signOut();
    dispatch(activeItem({ openItem: [defaultId] }));
    setOpen(false);
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    const fetchUserEmail = async () => {
      const attributes = await fetchUserAttributes();
      setEmail(attributes.email);
    };

    fetchUserEmail();
  }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 5 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? 'grey.300' : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: 'secondary.lighter' },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={1} alignItems="center" sx={{ p: 0.5 }}>
          <UserOutlined style={{ fontSize: '20px' }} />
          <Typography variant="subtitle1">{email}</Typography>
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 120,
                  minWidth: 120,
                  maxWidth: 120,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 120,
                  },
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    {open && (
                      <>
                        <ButtonBase
                          sx={{
                            p: 0.25,
                            borderRadius: 1,
                            '&:hover': { bgcolor: 'secondary.lighter' },
                          }}
                          onClick={handleLogout}
                          component={RouterLink}
                          to="/"
                        >
                          <Stack direction="row" spacing={1} alignItems="center" sx={{ p: 2 }}>
                            <LogoutOutlined />
                            <Typography>Logout</Typography>
                          </Stack>
                        </ButtonBase>
                      </>
                    )}
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
