// assets
import {
  CloudDownloadOutlined,
  CloudUploadOutlined,
  CodeOutlined,
  LayoutOutlined,
  ProfileOutlined,
} from '@ant-design/icons';

// icons
const icons = {
  CodeOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  LayoutOutlined,
  ProfileOutlined,
};

const pages =
  process.env.REACT_APP_USER_BRANCH === 'develop'
    ? {
        id: 'group-pages',
        title: 'Navigation',
        type: 'group',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/Dashboard',
            icon: icons.LayoutOutlined,
          },
          {
            id: 'vehiclemonitoring',
            title: 'Vehicle Monitoring',
            type: 'item',
            url: '/VehicleMonitoring',
            icon: icons.ProfileOutlined,
          },
          {
            id: 'dataacquisition',
            title: 'Data Acquisition',
            type: 'item',
            url: '/DataAcquisition',
            icon: icons.CloudDownloadOutlined,
          },
          {
            id: 'diagcommand',
            title: 'Diag Command',
            type: 'item',
            url: '/DiagCommand',
            icon: icons.CodeOutlined,
          },
          {
            id: 'dataupload',
            title: 'Data Upload',
            type: 'item',
            url: '/DataUpload',
            icon: icons.CloudUploadOutlined,
          },
          {
            id: 'vehiclestat',
            title: 'Vehicle Stat1',
            type: 'item',
            url: '/VehicleStat1',
            icon: icons.CloudDownloadOutlined,
          },
          {
            id: 'vehiclestat2',
            title: 'Vehicle Stat2',
            type: 'item',
            url: '/VehicleStat2',
            icon: icons.CloudDownloadOutlined,
          },
        ],
      }
    : {
        id: 'group-pages',
        title: 'Navigation',
        type: 'group',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/Dashboard',
            icon: icons.LayoutOutlined,
          },
          {
            id: 'vehiclemonitoring',
            title: 'Vehicle Monitoring',
            type: 'item',
            url: '/VehicleMonitoring',
            icon: icons.ProfileOutlined,
          },
          {
            id: 'dataacquisition',
            title: 'Data Acquisition',
            type: 'item',
            url: '/DataAcquisition',
            icon: icons.CloudDownloadOutlined,
          },
          {
            id: 'vehiclestat',
            title: 'Vehicle Stat1',
            type: 'item',
            url: '/VehicleStat1',
            icon: icons.CloudDownloadOutlined,
          },
          {
            id: 'vehiclestat2',
            title: 'Vehicle Stat2',
            type: 'item',
            url: '/VehicleStat2',
            icon: icons.CloudDownloadOutlined,
          },
        ],
      };

export default pages;
