import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - Vehicle Monitoring
const VehicleMonitoring = Loadable(lazy(() => import('pages/VehicleMonitoring')));

// render - Data Acquisition
const DataAcquisition = Loadable(lazy(() => import('pages/DataAcquisition')));

// render - Data Acquisition - csv display
const CsvDisplay = Loadable(lazy(() => import('pages/CsvDisplay')));

// render - Data Acquisition - chart display
const ChartDisplay = Loadable(lazy(() => import('pages/ChartDisplay')));

// render - Data Acquisition - map display
const MapDisplay = Loadable(lazy(() => import('pages/MapDisplay')));

// render - Diag command
const DiagCommand = Loadable(lazy(() => import('pages/DiagCommand')));

// render - Data Upload
const DataUpload = Loadable(lazy(() => import('pages/DataUpload')));

// render - Data Upload
const VehicleStat = Loadable(lazy(() => import('pages/VehicleStat')));

// render - Data Upload
const VehicleStat2 = Loadable(lazy(() => import('pages/VehicleStat2')));

// render - Not Found
const NotFound = Loadable(lazy(() => import('pages/NotFound')));

const MainRoutes =
  process.env.REACT_APP_USER_BRANCH === 'develop'
    ? {
        path: '/',
        element: <MainLayout />,
        children: [
          {
            path: '/',
            element: <DashboardDefault />,
          },
          {
            path: 'dashboard',
            element: <DashboardDefault />,
          },
          {
            path: 'vehiclemonitoring',
            element: <VehicleMonitoring />,
          },
          {
            path: 'dataacquisition',
            element: <DataAcquisition />,
          },
          {
            path: 'dataacquisition/csvdisplay',
            element: <CsvDisplay />,
          },
          {
            path: 'dataacquisition/chartdisplay',
            element: <ChartDisplay />,
          },
          {
            path: 'dataacquisition/mapdisplay',
            element: <MapDisplay />,
          },
          {
            path: 'diagcommand',
            element: <DiagCommand />,
          },
          {
            path: 'dataupload',
            element: <DataUpload />,
          },
          {
            path: 'vehiclestat1',
            element: <VehicleStat />,
          },
          {
            path: 'vehiclestat2',
            element: <VehicleStat2 />,
          },
          {
            path: '*',
            element: <NotFound />,
          },
        ],
      }
    : {
        path: '/',
        element: <MainLayout />,
        children: [
          {
            path: '/',
            element: <DashboardDefault />,
          },
          {
            path: 'dashboard',
            element: <DashboardDefault />,
          },
          {
            path: 'vehiclemonitoring',
            element: <VehicleMonitoring />,
          },
          {
            path: 'dataacquisition',
            element: <DataAcquisition />,
          },
          {
            path: 'vehiclestat1',
            element: <VehicleStat />,
          },
          {
            path: 'vehiclestat2',
            element: <VehicleStat2 />,
          },
          {
            path: '*',
            element: <NotFound />,
          },
        ],
      };

export default MainRoutes;
