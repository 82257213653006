import { useSelector } from 'react-redux';

// material-ui
import { Stack, Typography } from '@mui/material';

export default function UpdateTime() {
  const { displayUpdateTime } = useSelector((state) => state.menu);

  function makeUpdateTime() {
    let date = new Date(displayUpdateTime);
    let year = date.getUTCFullYear();
    let month = ('00' + (date.getUTCMonth() + 1)).slice(-2);
    let day = ('00' + date.getUTCDate()).slice(-2);
    let hour = ('00' + date.getUTCHours()).slice(-2);
    let minute = ('00' + date.getUTCMinutes()).slice(-2);
    let second = ('00' + date.getUTCSeconds()).slice(-2);

    return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
  }

  return (
    <Stack direction="row">
      <Typography variant="subtitle1" color="error">
        UTC
      </Typography>
      <Typography variant="subtitle1">:</Typography>
      <Typography variant="subtitle1" sx={{ whiteSpace: 'nowrap' }}>
        {makeUpdateTime()}
      </Typography>
    </Stack>
  );
}
