// material-ui
import { Box, Stack, Typography } from '@mui/material';

// project import
import Profile from './Profile';
import UpdateTime from './UpdateTime';

// assets
import MonitorOutlinedIcon from '@mui/icons-material/MonitorOutlined';

const HeaderContent = () => {
  return (
    <>
      <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
        <Stack direction="row" spacing={1} alignItems="center" sx={{ p: 0.5 }}>
          <MonitorOutlinedIcon />
          <Typography variant="h4">Monitoring System</Typography>
        </Stack>
      </Box>

      <UpdateTime />
      <Profile />
    </>
  );
};

export default HeaderContent;
