import { useMemo, useState } from 'react';

// material-ui
import { Typography } from '@mui/material';

// third-party
import { Amplify } from 'aws-amplify';
import { fetchUserAttributes, signOut } from 'aws-amplify/auth';
import {
  Authenticator,
  Heading,
  Image,
  useAuthenticator,
  useTheme,
  View,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import config from './amplifyconfiguration.json';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';

// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import HondaLogo from 'assets/images/honda_logo.png';

Amplify.configure(config);
cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);

const formFields = {
  signIn: {
    username: {
      placeholder: 'Enter your Email',
    },
  },
  confirmVerifyUser: {
    confirmation_code: {
      label: 'Code *',
      placeholder: 'Code',
      isRequired: false,
    },
  },
};

const components = {
  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.small}>
          <Image alt="Honda Logo" src={HondaLogo} />
          <Typography variant="h3">Monitoring System</Typography>
        </View>
      );
    },
  },

  VerifyUser: {
    Header() {
      return <Heading level={3}>Account recovery requires verified contact information</Heading>;
    },
  },

  ConfirmVerifyUser: {
    Header() {
      return <Heading level={3}>Account recovery requires verified contact information</Heading>;
    },
  },
};

const App = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  useMemo(async () => {
    if (route === 'authenticated') {
      const attributes = await fetchUserAttributes();
      if (attributes.email_verified === 'true') {
        setIsEmailVerified(true);
      } else {
        setIsEmailVerified(false);
        if (attributes.email_verified === 'false') {
          await signOut();
        }
      }
    } else {
      setIsEmailVerified(false);
    }
  }, [route]);

  return route === 'authenticated' && isEmailVerified ? (
    <ThemeCustomization>
      <ScrollTop>
        <Routes />
      </ScrollTop>
    </ThemeCustomization>
  ) : (
    <Authenticator
      variation="modal"
      formFields={formFields}
      components={components}
      hideSignUp={true}
    />
  );
};

export default App;
