// project import
import logo from 'assets/images/honda_logo.png';

const Logo = () => {
  return (
    <>
      <img src={logo} alt="Honda" width="200" />
    </>
  );
};

export default Logo;
