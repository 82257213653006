// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  openItem: ['dashboard'],
  defaultId: 'dashboard',
  openComponent: 'buttons',
  drawerOpen: false,
  componentDrawerOpen: true,
  displayUpdateTime: Date.now(),
  selectedVehicleNo: '',
  selectedTopics: [
    'tcu/stat-op',
    'can1/info-id707',
    'can1/info-id708',
    'can1/info-id709',
    'can1/info-id717',
    'can1/info-id718',
    'can1/info-id719',
    'can1/info-id730',
    'ad/stat',
    'ad/position',
    'ad/task',
  ],
  selectedDisplayTopic: '',
  dataStartTime: '',
  dataEndTime: '',
};

const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },

    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload.drawerOpen;
    },

    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    },

    setDisplayUpdateTime(state, action) {
      state.displayUpdateTime = action.payload.displayUpdateTime;
    },

    selectVehicleNo(state, action) {
      state.selectedVehicleNo = action.payload.selectedVehicleNo;
    },

    selectTopics(state, action) {
      state.selectedTopics = action.payload.selectedTopics;
    },

    selectDisplayTopic(state, action) {
      state.selectedDisplayTopic = action.payload.selectedDisplayTopic;
    },

    setDataStartTime(state, action) {
      state.dataStartTime = action.payload.dataStartTime;
    },

    setDataEndTime(state, action) {
      state.dataEndTime = action.payload.dataEndTime;
    },
  },
});

export default menu.reducer;

export const {
  activeItem,
  activeComponent,
  openDrawer,
  openComponentDrawer,
  setDisplayUpdateTime,
  selectVehicleNo,
  selectTopics,
  selectDisplayTopic,
  setDataStartTime,
  setDataEndTime,
} = menu.actions;
